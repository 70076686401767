<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ foodType.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.food-types.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" v-model="foodType.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="foodType.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="handleSave">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import FoodType from "@/models/FoodType";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-show",
    components: {DashboardLayout},
    data: function () {
        return {
            foodType: {}
        }
    },
    async mounted() {
        const foodTypeId = this.$route.params.id
        if (foodTypeId) {
            this.foodType = await FoodType.find(foodTypeId)
        }
    },
    methods: {
        async handleSave() {
            let foodType = new FoodType(this.foodType)
            foodType = await foodType.save()
            this.foodType = foodType
            await this.$router.push({name: 'admin.food-types.show', params: {id: foodType.id}})
        }
    }
}
</script>

<style scoped>

</style>
